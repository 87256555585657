const INIT_STATE: any = {
    axiosError: true,
    dayQuantity: 1231,
    variability: 0.2,
    deliveryRatio: 99.99,
    routes: 25
};

export const commonreducer = (state = INIT_STATE, action: any) => {
    switch (action.type) {
        case 'AXIOS_ERROR':
            return { ...state, axiosError: action?.payload };

        case "AXIOS_INICIAR_TMP":
            return {
                ...state,
                dayQuantity: action?.payload.dayQuantity,
                deliveryRatio: action?.payload.deliveryRatio,
                routes: action?.payload.routes,
                variability: action?.payload.variability
            };

        default:
            return state;
    }
};
