import { Fragment, useEffect } from "react";
import Footer from "../Footer/Footer";
import { Outlet, useNavigate } from "react-router-dom";
import Store from "../../Redux/store/store";
import { Provider, useSelector } from "react-redux";

export default function AppLogin() {
  /*control loguin*/
  const { token,user } = useSelector((store: any) => store.authreducer);

  const navigate = useNavigate()
  useEffect(() => {
    if (!token) {
      navigate(`${process.env.PUBLIC_URL}/`);
    } else {
      checkProfiles(user)
    }
  }, []);

  const checkProfiles = (user: any) => {
    let profiles = 0;
    var path = "";
    if (user.principal.staff) {
      path = `${process.env.PUBLIC_URL}/staff/inicio`;
      profiles++
    }
    if (user.principal.seller)
      profiles++
    if (user.principal.marketplace) {
      path = `${process.env.PUBLIC_URL}/marketplace/inicio`;
      profiles++
    }
    if (user.principal.driver) {
      path = `${process.env.PUBLIC_URL}/driver/inicio`;
      profiles++
    }

    if (profiles > 1)
      path = `${process.env.PUBLIC_URL}/login/profile`;

    navigate(path);
  }

  return (
    <Fragment>
      <Provider store={Store}>
        <div className="horizontalMenucontainer">
          <div className="page">
            <Outlet />
            <Footer />
          </div>
        </div>
      </Provider>
    </Fragment>
  );
}
