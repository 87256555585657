import { configureStore} from "@reduxjs/toolkit";
import persistedReducer from "../reducers/main";
import thunk from 'redux-thunk'


const store = configureStore({
    reducer: persistedReducer,
    middleware: [thunk],
}
);

export default store;
