export const logOut = (texto: string) => {
    return {
        type: "LOGOUT",
        payload: texto
    }
}

export const setUserInfo = (user: any) => {
    return {
        type: "USER_SET_INFO",
        payload: user
    }
}

export const logIn = (token: string) => {
    return {
        type: "LOGIN_SUCCESS",
        payload: token
    }
}