import { cartreducer } from "./reducer";
import { authreducer } from "./auth";
import storage from 'redux-persist/lib/storage';
import {persistReducer} from 'redux-persist' 
import { commonreducer } from "./common";
import { combineReducers } from "redux";
import { guidereducer } from "./guide";

const persistConfig = {
    key: 'root',
    storage,
    // whitelist: [] Aqui iran los datos que reciba de la api que quiero almacenar
}

const rootReducer = combineReducers({
    cartreducer,
    authreducer,
    guidereducer,
    commonreducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer)

export default persistedReducer;
