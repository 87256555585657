const INIT_STATE: any = {
    token: null,
    loginText: 'Credenciales invalidas',
    user: ""
};
export const authreducer = (state = INIT_STATE, action: any) => {
    switch (action.type) {
        case 'LOGIN_SUCCESS':
            return { ...state, token: action?.payload };
        
        case 'USER_SET_INFO':
            return { ...state, user: action?.payload };
        
        case 'LOGOUT': 
         return {...state, token: null, user:null, loginText: action?.payload}

        default:
            return state;
    }
};
