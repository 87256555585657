const INIT_STATE: any = {
    marketplaces: [],
    sellers: [],
    guideTypes: [],
    collectTypes: [],
    deliveryTypes:[],
    activeGuides: [],
};

export const guidereducer = (state = INIT_STATE, action: any) => {
    switch (action.type) {
        case 'SET_MARKETPLACES':
            return { ...state, marketplaces: action?.payload };
        case 'SET_SELLERS':
            return { ...state, sellers: action?.payload };
        case 'SET_GUIDE_TYPE':
            return { ...state, guideTypes: action?.payload };
        case 'SET_COLLECT_TYPE':
            return { ...state, collectTypes: action?.payload };
        case 'SET_DELIVERY_TYPE':
            return { ...state, deliveryTypes: action?.payload };
        case 'SET_ACTIVE_GUIDES':
            return { ...state, activeGuides: action?.payload };
            
        default:
            return state;
    }
};
