import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row, Alert, Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";
import ApiBackEnd from '../services/ApiBackEnd';
import { logIn } from '../Redux/actions/auth';
import { iniciarRadomPaquetesTMP } from '../Redux/actions/common';
import Scrollbars from 'react-custom-scrollbars';

const LoginProfile = () => {
  const { user } = useSelector((store: any) => store.authreducer);
  const dispatch = useDispatch()
  let navigate = useNavigate();

  const routeChange = () => {
    //let path = `${process.env.PUBLIC_URL}/staff/inicio`;
    //navigate(path);
  }

  const { token } = useSelector((store: any) => store.authreducer);

  useEffect(() => {
    
  }, []);

  return (
    <React.Fragment>
      <div className="square-box"> <div></div> <div></div> <div></div> <div></div> <div></div> <div></div> <div></div> <div></div> <div></div> <div></div> <div></div> <div></div> <div></div> <div></div> <div></div> </div>
      <div className="page bg-primary">
        <div className="page-single">
          <div className="container" style={{ marginTop: "89px" }} >
            <Row>
              <Col
                xl={5}
                lg={6}
                md={8}
                sm={8}
                xs={10}
                className="card-sigin-main mx-auto my-auto py-4 justify-content-center"
              >
                <div className="card-sigin">
                  {/* <!-- Demo content--> */}
                  <div className="main-card-signin d-md-flex">
                    <div className="wd-100p">
                      <div className="d-flex mb-4">
                        <Link to="#">
                          <img
                            src={require("../assets/img/brand/favicon.png")}
                            className="sign-favicon ht-40"
                            alt="logo"
                          />
                        </Link>
                      </div>
                      <div className="">
                        <div className="main-signup-header">
                          <h3>Seleccionar perfil</h3>
                          <Scrollbars style={{ height: 280 }}>
                            <div className="main-message-list chat-scroll">
                              {user?.principal?.staff &&
                                <Dropdown.Item
                                  href={`${process.env.PUBLIC_URL}/staff/inicio`}
                                  className="dropdown-item d-flex border-bottom"
                                >
                                  <img
                                    className="  drop-img  cover-image  "
                                    alt=""
                                    src={require("../assets/img/faces/3.jpg")}
                                  />
                                  <span className="avatar-status bg-teal avatar-status1"></span>
                                  <div className="wd-90p">
                                    <div className="d-flex">
                                      <h2 className="mb-10 name">Staff</h2>
                                    </div>
                                  </div>
                                </Dropdown.Item>
                              }
                              {user?.principal?.driver &&
                                <Dropdown.Item
                                  href={`${process.env.PUBLIC_URL}/driver/inicio`}
                                  className="dropdown-item d-flex border-bottom"
                                >
                                  <img
                                    className="  drop-img  cover-image  "
                                    alt=""
                                    src={require("../assets/img/faces/3.jpg")}
                                  />
                                  <span className="avatar-status bg-teal avatar-status1"></span>
                                  <div className="wd-90p">
                                    <div className="d-flex">
                                      <h2 className="mb-10 name">Driver</h2>
                                    </div>
                                  </div>
                                </Dropdown.Item>
                              }
                              {user?.principal?.marketplace &&
                                <Dropdown.Item
                                  href={`${process.env.PUBLIC_URL}/marketplace/inicio`}
                                  className="dropdown-item d-flex border-bottom"
                                >
                                  <img
                                    className="  drop-img  cover-image  "
                                    alt=""
                                    src={require("../assets/img/faces/3.jpg")}
                                  />
                                  <span className="avatar-status bg-teal avatar-status1"></span>
                                  <div className="wd-90p">
                                    <div className="d-flex">
                                      <h2 className="mb-10 name">Marketplace</h2>
                                    </div>
                                  </div>
                                </Dropdown.Item>
                              }
                            </div>
                          </Scrollbars>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div >
      </div>
    </React.Fragment>
  );
}

LoginProfile.propTypes = {};

LoginProfile.defaultProps = {};

export default LoginProfile;
